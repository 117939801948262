<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'biura',
	mixins: [handleWith],
}
</script>

<template>
	<Offer>
		<template #banner>
			<img src="./img/biura.jpg" class="fixed-image" alt="banner">
		</template>
		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>
		<template #small-title>
			<h4>PRAWO PODATKOWE</h4>
		</template>

		<template #title>
			<h2>Wsparcie Biur Rachunkowych
			</h2>
		</template>

		<template #text>
			<p>Współpracujemy z biurami rachunkowymi, zapewniając im wsparcie merytoryczne i konsultacje
				prawno-podatkowe w złożonych sprawach klientów. Dzięki temu biura rachunkowe mogą dostarczać swoim
				klientom usługi na najwyższym poziomie, minimalizując ryzyko podatkowe.

			</p>
		</template>
	</Offer>
</template>

<style scoped lang="scss">
.fixed-image {
	object-fit: cover;
	object-position: 50% 66%;
}
</style>
